// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ApplicationForm from './components/ApplicationForm';
import AdminDashboard from './components/AdminDashboard';
import StatusCheck from './components/StatusCheck';

const App = () => {
  return (
    <Router className="bg-gray-200">
      <div className="w-full">
        <Routes>
          <Route path="/" element={<ApplicationForm />} />
          <Route path="/kalchuri/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/kalchuri/admin-dashboard/application/:id" element={<AdminDashboard />} />
          <Route path="/status-check" element={<StatusCheck />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
