// src/components/ApplicationForm.js
import React, { useState } from "react";
import { db } from "../firebase/firebase"; // Import your Firebase configuration
import { collection, setDoc, doc, Timestamp } from "firebase/firestore"; // Import Firestore methods
import Lottie from "lottie-react"; // Import Lottie for animations
import animationData from "../assets/success.json"; // Your Lottie animation
import { jsPDF } from "jspdf";
import { Link } from "react-router-dom"; // Import Link for routing

const ApplicationForm = () => {
  const [startupName, setStartupName] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [pitchDeckLink, setPitchDeckLink] = useState("");
  const [description, setDescription] = useState("");
  const [industry, setIndustry] = useState("");
  const [teamDocumentLink, setTeamDocumentLink] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [stageOfDevelopment, setStageOfDevelopment] = useState("");
  const [uniqueSellingProposition, setUniqueSellingProposition] = useState("");
  const [targetAudience, setTargetAudience] = useState("");
  const [marketSize, setMarketSize] = useState("");
  const [competitors, setCompetitors] = useState("");
  const [userBase, setUserBase] = useState("");
  const [revenue, setRevenue] = useState("");
  const [keyMetrics, setKeyMetrics] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [uniqueCode, setUniqueCode] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [submissionDateTime, setSubmissionDateTime] = useState(""); // New state for submission date and time

  // Function to generate a random 5-digit code
  const generateUniqueCode = () => {
    return Math.floor(10000 + Math.random() * 90000).toString();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const generatedCode = generateUniqueCode(); // Generate a unique code
    const currentDateTime = new Date().toISOString(); // Capture the current date and time in ISO format
    setSubmissionDateTime(currentDateTime); // Set the submission date and time state

    try {
      // Create a reference to the document using the generated code
      const docRef = doc(collection(db, "applications"), generatedCode);

      // Set the document with the custom ID
      await setDoc(docRef, {
        startupName,
        websiteUrl,
        pitchDeckLink,
        description,
        industry,
        teamDocumentLink,
        productDescription,
        stageOfDevelopment,
        uniqueSellingProposition,
        targetAudience,
        marketSize,
        competitors,
        userBase,
        revenue,
        keyMetrics,
        email,
        phone,
        uniqueCode: generatedCode, // Store the generated code as uniqueCode
        submissionDateTime: currentDateTime // Store the submission date and time
      });

      console.log("Document written with ID: ", generatedCode);

      // Generate PDF with the unique code after setting document in Firebase
      const pdfDoc = new jsPDF();
      pdfDoc.text(`Your Unique Code: ${generatedCode}`, 10, 10);

      // Save PDF and open modal
      pdfDoc.save("UniqueCode.pdf");
      setUniqueCode(generatedCode);
      setShowSuccess(true);
      setShowModal(true); // Open modal after successful submission

      clearForm();
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const clearForm = () => {
    setStartupName("");
    setWebsiteUrl("");
    setPitchDeckLink("");
    setDescription("");
    setIndustry("");
    setTeamDocumentLink("");
    setProductDescription("");
    setStageOfDevelopment("");
    setUniqueSellingProposition("");
    setTargetAudience("");
    setMarketSize("");
    setCompetitors("");
    setUserBase("");
    setRevenue("");
    setKeyMetrics("");
    setEmail("");
    setPhone("");
    setSubmissionDateTime()
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="w-full h-full bg-[#fdead8] py-10">
      <div className="max-w-4xl mx-auto bg-[white] p-10 rounded-lg shadow-lg">
      {/* Check Application Status Button */}
      <div className="mb-5 w-full md:grid grid-cols-8 flex flex-col gap-1 justify-between">
      <p className="text-sm font-semibold text-gray-500 mt-3 bg-red-50 col-span-6 border-l-4 border-red-500 p-2">
            Note: If you have already submitted your application, your can check your application status here. Frequent submissions will lead to permanent ban from incubation. 
          </p>
        <Link
          to="/status-check" // Adjust the route as per your setup
          className="bg-blue-600 text-white col-span-2 flex justify-center items-center py-4 rounded-lg w-full hover:bg-blue-500"
        >
          <span className="text-sm font-semibold"> Check Application Status</span>
        </Link>
      </div>
      <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">
        Kalchuri LNCT Incubation Center Application Form
      </h1>

      {/* Form Section */}
      {!showModal && (
        <form onSubmit={handleSubmit}>
          {/* Startup Information */}
          <h3 className="text-xl font-semibold mt-6 mb-3 text-gray-700">
            Startup Information
          </h3>
          <input
            required
            type="text"
            placeholder="Startup Name"
            value={startupName}
            onChange={(e) => setStartupName(e.target.value)}
            className="mt-2 border border-gray-300 p-4 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            required
            type="text"
            placeholder="Website URL"
            value={websiteUrl}
            onChange={(e) => setWebsiteUrl(e.target.value)}
            className="mt-2 border border-gray-300 p-4 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            required
            type="text"
            placeholder="Pitch Deck Link"
            value={pitchDeckLink}
            onChange={(e) => setPitchDeckLink(e.target.value)}
            className="mt-2 border border-gray-300 p-4 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <textarea
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="mt-2 border border-gray-300 p-4 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          ></textarea>
          <input
            required
            type="text"
            placeholder="Industry"
            value={industry}
            onChange={(e) => setIndustry(e.target.value)}
            className="mt-2 border border-gray-300 p-4 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />

          {/* Team Information */}
          <h3 className="text-xl font-semibold mt-6 mb-3 text-gray-700">
            Team Information<span className="text-red-500">*</span>
          </h3>
          <p className="text-sm text-gray-500 mb-2">
            For reference, download this template and fill in the required
            details.
          </p>
          <a
            href="/Team_Members_KLIC_Kalchuri.docx" // Replace with your actual file path
            download
            className="text-blue-600 underline mb-3 block"
          >
            Download Template
          </a>
          <input
            required
            type="text"
            placeholder="Team Document Link"
            value={teamDocumentLink}
            onChange={(e) => setTeamDocumentLink(e.target.value)}
            className="mt-2 border border-gray-300 p-4 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <p className="text-sm text-gray-500 mt-1">
            Please provide a document (PDF, Word) containing team member name,
            bios, roles, and relevant experience.
          </p>
          <p className="text-sm font-semibold text-gray-500 mt-3 bg-yellow-50 border-l-4 border-yellow-500 p-2">
            Note: For students, the following information is required:
            Enrollment number, College, Year of passing, Current Sem, Course,
            and Branch.
          </p>

          {/* Product Information */}
          <h3 className="text-xl font-semibold mt-6 mb-3 text-gray-700">
            Product Information
          </h3>
          <textarea
            placeholder="Product/Service Description"
            value={productDescription}
            onChange={(e) => setProductDescription(e.target.value)}
            className="mt-2 border border-gray-300 p-4 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          ></textarea>
          <input
            required
            type="text"
            placeholder="Stage of Development"
            value={stageOfDevelopment}
            onChange={(e) => setStageOfDevelopment(e.target.value)}
            className="mt-2 border border-gray-300 p-4 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            required
            type="text"
            placeholder="Unique Selling Proposition"
            value={uniqueSellingProposition}
            onChange={(e) => setUniqueSellingProposition(e.target.value)}
            className="mt-2 border border-gray-300 p-4 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />

          {/* Market and Competition */}
          <h3 className="text-xl font-semibold mt-6 mb-3 text-gray-700">
            Market and Competition
          </h3>
          <input
            required
            type="text"
            placeholder="Target Audience"
            value={targetAudience}
            onChange={(e) => setTargetAudience(e.target.value)}
            className="mt-2 border border-gray-300 p-4 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            required
            type="text"
            placeholder="Market Size"
            value={marketSize}
            onChange={(e) => setMarketSize(e.target.value)}
            className="mt-2 border border-gray-300 p-4 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            required
            type="text"
            placeholder="Competitors"
            value={competitors}
            onChange={(e) => setCompetitors(e.target.value)}
            className="mt-2 border border-gray-300 p-4 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />

          {/* User Base and Revenue */}
          <h3 className="text-xl font-semibold mt-6 mb-3 text-gray-700">
            User Base and Revenue
          </h3>
          <input
            required
            type="text"
            placeholder="Current User Base"
            value={userBase}
            onChange={(e) => setUserBase(e.target.value)}
            className="mt-2 border border-gray-300 p-4 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            required
            type="text"
            placeholder="Monthly Revenue"
            value={revenue}
            onChange={(e) => setRevenue(e.target.value)}
            className="mt-2 border border-gray-300 p-4 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            required
            type="text"
            placeholder="Key Metrics"
            value={keyMetrics}
            onChange={(e) => setKeyMetrics(e.target.value)}
            className="mt-2 border border-gray-300 p-4 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />

          {/* Contact Information */}
          <h3 className="text-xl font-semibold mt-6 mb-3 text-gray-700">
            Contact Information
          </h3>
          <input
            required
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-2 border border-gray-300 p-4 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            required
            type="tel"
            placeholder="Phone Number"
            value={phone}
            onChange={(e) => {
              const newValue = e.target.value;
              // Allow only digits
              if (/^\d*$/.test(newValue) && newValue.length <= 15) {
                setPhone(newValue);
              }
            }}
            pattern="[0-9]{10,15}" // 10 to 15 digits, adjust as per your requirement
            title="Please enter a valid phone number (10-15 digits)"
            className="mt-2 border border-gray-300 p-4 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />

          <button
            type="submit"
            className="mt-6 bg-blue-600 text-white p-4 rounded-lg w-full hover:bg-blue-500"
          >
            Submit Application
          </button>
        </form>
      )}
      {/* Success Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-10 shadow-lg">
            <h2 className="text-2xl font-bold text-center text-gray-800 mb-4">
              Application Submitted Successfully!
            </h2>
            <Lottie
              animationData={animationData}
              loop={true}
              onComplete={() => setShowSuccess(true)}
              className="w-48 mx-auto mb-4"
            />
            <p className="text-center text-lg text-gray-700">
              Your unique code is:{" "}
              <span className="font-semibold">{uniqueCode}</span>
            </p>
            {/* <p className="text-center text-gray-600 mt-2">
              Submission Date and Time:{" "}
              {new Date(submissionDateTime).toLocaleString()}
            </p> */}
            <button
              onClick={handleCloseModal}
              className="mt-6 bg-blue-500 text-white p-4 rounded-lg hover:bg-blue-600 transition duration-300 w-full"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
    </div>
    
  );
};

export default ApplicationForm;
