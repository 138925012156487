import React, { useState } from 'react';
import { db } from '../firebase/firebase'; // Import the Firebase configuration
import { doc, getDoc } from 'firebase/firestore';

const StatusCheck = () => {
  const [uniqueCode, setUniqueCode] = useState('');
  const [application, setApplication] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCheckStatus = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    const trimmedCode = uniqueCode.trim();
    
    console.log('Checking status for unique code:', trimmedCode);

    if (!trimmedCode) {
      setError('Unique code cannot be empty.');
      setLoading(false);
      return;
    }

    const docRef = doc(db, 'applications', trimmedCode);

    try {
      const docSnap = await getDoc(docRef);
      console.log('Document exists:', docSnap.exists()); // Log whether the document exists

      if (docSnap.exists()) {
        console.log('Application found:', docSnap.data());
        setApplication(docSnap.data());
      } else {
        console.error('No application found with this unique code.');
        setError('No application found with this unique code.');
        setApplication(null);
      }
    } catch (error) {
      console.error('Error retrieving application status:', error);
      setError('Error retrieving application status. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 max-w-md mx-auto bg-white rounded-lg shadow-lg border border-gray-200">
  <h1 className="text-2xl font-semibold text-center text-gray-800 mb-6">
    Check Application Status
  </h1>
  
  {/* Status Check Form */}
  <form onSubmit={handleCheckStatus} className="space-y-4">
    <input
      type="text"
      value={uniqueCode}
      onChange={(e) => setUniqueCode(e.target.value)}
      placeholder="Enter your unique code"
      className="w-full border border-gray-300 p-3 rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
      required
    />
    <button
      type="submit"
      className="w-full bg-blue-600 text-white font-semibold py-3 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
    >
      Check Status
    </button>
  </form>

  {/* Loading and Error Messages */}
  {loading && (
    <p className="text-center text-gray-500 mt-4">Checking status...</p>
  )}
  {error && (
    <p className="text-center text-red-500 mt-4">{error}</p>
  )}

  {/* Application Details */}
  {application && (
    <div className="mt-8 bg-gray-50 p-4 rounded-lg shadow-inner">
      <h2 className="text-lg font-semibold text-gray-800 mb-3">
        Application Details
      </h2>
      <div className="space-y-2 text-gray-700">
        <p>
          <span className="font-medium">Startup Name:</span> {application.startupName}
        </p>
        <p>
          <span className="font-medium">Email:</span> {application.email}
        </p>
        <p>
          <span className="font-medium">Status:</span> {application.status || 'Pending'}
        </p>
        {application.comment && (
          <p>
            <span className="font-medium">Comment:</span> {application.comment}
          </p>
        )}
      </div>
    </div>
  )}
</div>

  );
};

export default StatusCheck;
