// src/components/AdminDashboard.js
import React, { useEffect, useState } from "react";
import { db, auth } from "../firebase/firebase";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import Modal from "react-modal";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { IoMdClose, IoMdMenu } from "react-icons/io";
import { GoPencil } from "react-icons/go";
import * as XLSX from 'xlsx';

const AdminDashboard = () => {
  const [applications, setApplications] = useState([]);
  const [filteredApplications, setFilteredApplications] = useState([]); // Add filtered applications
  const [filter, setFilter] = useState("All"); // Track selected filter
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [comment, setComment] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAdmin, setIsAdmin] = useState(false); // New state for admin
  const [isSubAdmin, setIsSubAdmin] = useState(false); // New state for subAdmin
  const [isRegistering, setIsRegistering] = useState(false); // State to toggle between Register and Login
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to check if user is logged in
  const [userDoc, setUserDoc] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 10;

  useEffect(() => {
    // Fetch applications and set them to state
    // setApplications(fetchedApplications);
    // setFilteredApplications(fetchedApplications);
  }, []);

  const sortedApplications = [...filteredApplications].sort((a, b) => new Date(b.submissionDateTime) - new Date(a.submissionDateTime));

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedApplications.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(filteredApplications.length / entriesPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

 
//Code added to handle modal and admin back issue
const { id } = useParams();  // Get the application ID from the URL
const navigate = useNavigate();
const location = useLocation();

useEffect(() => {
  // Open modal if there's an ID in the URL
  if (id) {
    const selectedApp = applications.find(app => app.id === id);
    setSelectedApplication(selectedApp || null);
    setModalIsOpen(!!selectedApp);
  } else {
    setModalIsOpen(false);
    setSelectedApplication(null);
  }
}, [id, applications]);

const openModal = (application) => {
  setComment(""); // Reset comment input
  navigate(`/kalchuri/admin-dashboard/application/${application.id}`); // Navigate to modal route
};

const closeModal = () => {
  navigate("/kalchuri/admin/dashboard"); // Go back to main dashboard route
};

useEffect(() => {
  handleDateFilterChange();
}, [selectedMonth, selectedYear]);



  const fetchApplications = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "applications"));
      const apps = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setApplications(apps);
      setFilteredApplications(apps); // Initialize filtered applications
    } catch (err) {
      setError("Failed to fetch applications. Please try again later.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Reset isEditing when the component unmounts
useEffect(() => {
    setIsEditing(false);
}, [location]);

  useEffect(() => {
    fetchApplications();
  }, []);

  // const openModal = (application) => {
  //   setSelectedApplication(application);
  //   setComment(""); // Reset comment input
  //   setModalIsOpen(true);
  // };

  // const closeModal = () => {
  //   setModalIsOpen(false);
  //   setSelectedApplication(null);
  // };

  const formatSubmissionDate = (dateString) => {
    const submissionDate = new Date(dateString);
    const day = submissionDate.getDate().toString().padStart(2, '0');
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[submissionDate.getMonth()];
    const year = submissionDate.getFullYear();
    const hours = submissionDate.getHours();
    const minutes = submissionDate.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0'); // Convert to 12-hour format and pad with leading zero if necessary
    return `${day} ${month} ${year} ${formattedHours}:${minutes} ${ampm}`;
  };

  const handleAccept = async () => {
    try {
      if (comment != "") {
        await updateDoc(doc(db, "applications", selectedApplication.id), {
          status: "Accepted",
          comment: comment,
        });
        closeModal();
        fetchApplications();
      } // Refresh applications list
      else {
        alert("Please add a comment before accepting the application."); // Alert for empty comment
      }
    } catch (err) {
      setError("Failed to update application status. Please try again later.");
      console.error(err);
    }
  };

  const handleReject = async () => {
    try {
      if (comment !== "") {
        await updateDoc(doc(db, "applications", selectedApplication.id), {
          status: "Rejected",
          comment: comment,
        });
        closeModal();
        fetchApplications(); // Refresh applications list
      } else {
        alert("Please add a comment before rejecting the application."); // Alert for empty comment
      }
    } catch (err) {
      setError("Failed to update application status. Please try again later.");
      console.error(err);
    }
  };

  // Handle User Registration
  const handleRegister = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      // Set user document with isAdmin
      await setDoc(doc(db, "users", userCredential.user.uid), {
        Rights: {
          isAdmin: isAdmin,
          isSubAdmin: isSubAdmin,
        }, // Set admin status to false
      });
      alert("User registered successfully!");
      resetForm();
    } catch (err) {
      setError("Failed to register user. Please try again later.");
      console.error(err);
    }
  };
  // Handle User Login
  const handleLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const userDoc = await getDoc(doc(db, "users", userCredential.user.uid));
      if (userDoc.exists() && (userDoc.data().Rights.isAdmin || userDoc.data().Rights.isSubAdmin)) {
        alert("Admin logged in successfully!");
        setIsLoggedIn(true); // Update logged in status
        setIsAdmin(userDoc.data().isAdmin);
        setIsSubAdmin(userDoc.data().isSubAdmin);
        setUserDoc(userDoc)
      } else {
        alert("You do not have admin or SubAdmin privileges.");
        auth.signOut(); // Sign out if not an admin
      }
      resetForm();
    } catch (err) {
      setError("Failed to login. Please check your credentials.");
      console.error(err);
    }
  };

  // Handle User Logout
  const handleLogout = async () => {
    try {
      await signOut(auth);
      setIsLoggedIn(false); // Reset logged in status
      alert("Logged out successfully!");
    } catch (err) {
      setError("Failed to log out. Please try again later.");
      console.error(err);
    }
  };

  const exportToExcel = () => {
    const data = filteredApplications.map(app => ({
      'Startup Name': app.startupName,
      'Email': app.email,
      'Phone': app.phone,
      'Status': app.status || 'Pending',
      'Unique Code': app.uniqueCode,
      'Submission Date and Time': formatSubmissionDate(app.submissionDateTime),
      'Comment': app.comment || 'No comment',
      'Website URL': app.websiteUrl,
      'Pitch Deck Link': app.pitchDeckLink,
      'Team Document Link': app.teamDocumentLink,   
      'Description': app.description,
      'Industry': app.industry,
      'Product Description': app.productDescription,
      'Stage of Development': app.stageOfDevelopment,
      'Unique Selling Proposition': app.uniqueSellingProposition,
      'Target Audience': app.targetAudience,
      'Market Size': app.marketSize,
      'Competitors': app.competitors,
      'User Base': app.userBase,
      'Revenue': app.revenue,
      'Key Metrics': app.keyMetrics
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Applications');
  
    // Apply styles to the header cells
    const headerCells = Object.keys(data[0]).map((_, index) => String.fromCharCode(65 + index) + '1');
    headerCells.forEach(cell => {
      if (worksheet[cell]) {
        worksheet[cell].s = {
          font: {
            bold: true
          }
        };
      }
    });
  
    XLSX.writeFile(workbook, 'Applications.xlsx');
  };

    //Filter applications on Date
    const handleDateFilterChange = () => {
      let filteredApps = applications;
      const fullYear = (filteredApps.map(app => app.submissionDateTime));
      const dateObj = fullYear.map(app => new Date(app));
      const year = dateObj.map(app => app.getFullYear());
      const month = dateObj.map(app => app.getMonth() + 1);
      
      if(selectedYear){
        filteredApps = filteredApps.filter(app => year.includes(parseInt(selectedYear, 10)));
      }
      if(selectedMonth){
        filteredApps = filteredApps.filter(app => month.includes(parseInt(selectedMonth, 10)));
      }

      setFilteredApplications(filteredApps);
    };

    // Filter applications based on status
    const handleFilterChange = (status) => {
      setFilter(status);
      if (status === "All") {
        setFilteredApplications(applications);
      } else if  (status === "Pending") {
        setFilteredApplications(applications.filter(app => (app.status !== "Accepted" && app.status !== "Rejected")));
      }
      else {
        setFilteredApplications(applications.filter(app => app.status === status));
      }
    };

  const formatWebsiteUrl = (url) => {
    // Check if the URL starts with http:// or https://
    if (!/^https?:\/\//i.test(url)) {
      return `http://${url}`; // Add http:// if not present
    }
    return url; // Return the original URL if it's valid
  };

  const resetForm = () => {
    setEmail("");
    setPassword("");
    
  };

  return (
    <>
    <div className={`${isLoggedIn ? "md:justify-end h-full items-center" : "justify-center h-[650px] items-center"} w-[100%] flex`}>
    { isLoggedIn &&
       <div className={`fixed md:block ${sidebarOpen ? "hidden" : "block"} top-0 left-0 h-full w-64 bg-gray-800 text-white border-r-2 border-gray-800
      }`} >
        <h2 className="text-xl text-center mt-10 font-bold mb-4">Filter by STATUS</h2>
        <button onClick={() => handleFilterChange("All")} className={`block rounded-md w-full py-2 px-4 text-left ${filter === "All" ? "bg-white text-gray-800" : "bg-gray-800 text-white" }`}>
          All
        </button>
        <button onClick={() => handleFilterChange("Accepted")} className={`block rounded-md w-full py-2 px-4 text-left ${filter === "Accepted" ? "bg-white text-gray-800" : "bg-gray-800 text-white" }`}>
          Accepted
        </button>
        <button onClick={() => handleFilterChange("Rejected")} className={`block rounded-md w-full py-2 px-4 text-left ${filter === "Rejected" ? "bg-white text-gray-800" : "bg-gray-800 text-white" }`}>
          Rejected
        </button>
        <button onClick={() => handleFilterChange("Pending")} className={`block rounded-md w-full py-2 px-4 text-left ${filter === "Pending" ? "bg-white text-gray-800" : "bg-gray-800 text-white"}`}>
          Pending
        </button>

        <div className=" flex flex-col mt-12 ">
        <h2 className="text-xl text-center font-bold mb-4">Filter by DATE</h2>
        <div>
          <div>
      <label className="text-lg font-semibold ml-2"> Month:</label>
      <select className="bg-gray-800 border-black rounded-md mb-6 ml-6 p-2 border-2" value={selectedMonth} onChange={(e) => {setSelectedMonth(e.target.value); handleDateFilterChange()}}>
        <option value="">All</option>
        <option value="1">January</option>
        <option value="2">February</option>
        <option value="3">March</option>
        <option value="4">April</option>
        <option value="5">May</option>
        <option value="6">June</option>
        <option value="7">July</option>
        <option value="8">August</option>
        <option value="9">September</option>
        <option value="10">October</option>
        <option value="11">November</option>
        <option value="12">December</option>
      </select>
      </div>

      <div>
      <label className="text-lg font-semibold ml-2">Year:</label>
      <select className="bg-gray-800 border-black rounded-md ml-6 p-2 border-2" value={selectedYear} onChange={(e) => {setSelectedYear(e.target.value); handleDateFilterChange()}}>
        <option value="">All</option>
        {/* Add options for years dynamically or statically */}
        <option value="2024">2024</option>
        <option value="2023">2023</option>
        <option value="2022">2022</option>
        <option value="2021">2021</option>
        {/* Add more years as needed */}
      </select>
      </div>
    </div>
        </div>
      </div>
    }
  
  <button onClick={() => setSidebarOpen(!sidebarOpen)} className={`absolute ${sidebarOpen ? "text-black" : "text-white"} md:hidden font-bold text-xl top-5 left-5 p-4`}>{sidebarOpen ? <IoMdMenu />  : <IoMdClose />}</button>

<div className={`${isLoggedIn ? "block" : "absolute"} max-w-5xl lg:max-w-6xl 2xl:mr-28 bg-white p-10 rounded-lg shadow-lg`}>
       
      <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">
        Applications
      </h1>
      {loading ? (
        <p>Loading applications...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : isLoggedIn ? ( // Show applications if logged in (only)
        <div className="">
          <div className="flex justify-end mb-4">
          <button
        onClick={exportToExcel}
        className="bg-green-600 text-white p-2 rounded-lg mb-4 object-end"
      >
        Export to Excel
      </button>
          </div>
          
          <div className="overflow-x-auto flex justify-start">
          <table className="min-w-full text-xs md:text-base">
            <thead>
              <tr>
                <th className="border px-4 py-2">Startup Name</th>
                <th className="border px-4 py-2 hidden md:table-cell">Email</th>
                <th className="border px-4 py-2">Status</th>
                <th className="border px-4 py-2 hidden md:table-cell">Unique Code</th>
                <th className="border px-4 py-2">Submission Date and Time</th>
                <th className="border px-4 py-2 hidden md:table-cell">Comment</th>
                <th className="border px-4 py-2">Details</th>
              </tr>
            </thead>
            <tbody>
            {currentEntries.map((app) => {
                console.log(app.submissionDateTime); // Log the submission date to the console

                const submissionDate = new Date(app.submissionDateTime);
                // const day = submissionDate.getDate().toString().padStart(2, '0');
                // const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                // const month = monthNames[submissionDate.getMonth()];
                // const year = submissionDate.getFullYear();
                // const hours = submissionDate.getHours();
                // const minutes = submissionDate.getMinutes().toString().padStart(2, '0');
                // const ampm = hours >= 12 ? 'PM' : 'AM';
                // const formattedHours = (hours % 12 || 12).toString().padStart(2, '0'); // Convert to 12-hour format and pad with leading zero if necessary
                const formattedDateTime = formatSubmissionDate(app.submissionDateTime);
               
               

                // Example output: "10/28/2024, 02:30 PM"

                return (
                  <tr key={app.id}>
                    <td className="border px-4 py-2">{app.startupName}</td>
                    <td className="border px-4 py-2 hidden md:table-cell">{app.email}</td>
                    <td className={`border px-4 py-2`}>
                  <span className={`border px-2 py-1 rounded-lg shadow-sm ${app.status == "Accepted" &&  "bg-[#a5e8d3]"  }  ${app.status == "Rejected" &&  "bg-red-300"} ${!app.status && "bg-[#F9e7ab]"}`} >
                  {app.status || "Pending"}
                  </span>
                    </td>
                    <td className="border px-4 py-2 hidden md:table-cell">{app.uniqueCode}</td>
                    <td className="border px-10 py-2">
                      {isNaN(submissionDate)
                        ? "Invalid Date"
                        : formattedDateTime}
                    </td>
                    <td className="border px-10 py-2 hidden md:table-cell">
                    {app.comment ? app.comment : "No comment"}
                    </td>
                    <td className="border px-4 py-2">
                      <button
                        onClick={() => openModal(app)}
                        className="text-blue-600 hover:underline"
                      >
                        View Details
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          </div>
          <div className="flex justify-center mt-4">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={`px-4 py-2 mx-1 rounded ${currentPage === index + 1 ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'}`}
          >
            {index + 1}
          </button>
        ))}
      </div>
          <button
            onClick={handleLogout}
            className="bg-red-600 text-white p-2 rounded-lg mt-4"
          >
            Logout
          </button>
        </div>
      ) : (
        // Show login/register if not logged in
        <div>
          <div className="mt-8 flex justify-between mb-4">
            <button
              onClick={() => setIsRegistering(false)}
              className={`py-2 px-4 rounded-lg ${
                !isRegistering
                  ? "bg-green-600 text-white"
                  : "bg-gray-200 text-gray-800"
              }`}
            >
              Login
            </button>
            <button
              onClick={() => setIsRegistering(true)}
              className={`py-2 px-4 rounded-lg ${
                isRegistering
                  ? "bg-blue-600 text-white"
                  : "bg-gray-200 text-gray-800"
              }`}
            >
              Register
            </button>
          </div>

          {/* Conditional Rendering for Registration/Login Forms */}
          {isRegistering ? (
            <div>
              <h2 className="text-xl font-bold">Register Admin</h2>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border p-2 rounded-lg w-full mb-2"
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="border p-2 rounded-lg w-full mb-2"
              />
              {/* <label className="inline-flex items-center mb-4">
                <input
                  type="checkbox"
                  checked={isAdmin}
                  onChange={() => setIsAdmin(!isAdmin)}
                  className="mr-2"
                />
                Is Admin
              </label> */}
              <button
                onClick={handleRegister}
                className="bg-blue-600 text-white p-2 rounded-lg"
              >
                Register
              </button>
            </div>
          ) : (
            <div>
              <h2 className="text-xl font-bold">Admin Login</h2>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border p-2 rounded-lg w-full mb-2"
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="border p-2 rounded-lg w-full mb-2"
              />
              <button
                onClick={handleLogin}
                className="bg-green-600 text-white p-2 rounded-lg"
              >
                Login
              </button>
            </div>
          )}
          {error && <p className="text-red-500">{error}</p>}
        </div>
      )}
      
      {/* Modal for application details */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Application Details"
          className="fixed inset-0 bg-white p-8 overflow-auto items-center"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 overflow-auto flex justify-center items-center"
        >
          {selectedApplication && (
            <>
                        <h2 className="text-3xl font-bold mb-6 text-center">
            Application Details
          </h2>

          <div className="space-y-4">
          <div className="overflow-x-auto rounded-md">
  <table className="min-w-full text-left text-sm md:text-base">
    <tbody>
      <tr className="border-b bg-gray-200 border-white">
        <td className="px-4 py-2 font-semibold w-1/4 bg-gray-600 text-white">Startup Name:</td>
        <td className="px-4 py-2 w-3/4">{selectedApplication.startupName}</td>
      </tr>
      <tr className="border-b bg-gray-200 border-white">
        <td className="px-4 py-2 font-semibold w-1/4 bg-gray-600 text-white">Time of Application:</td>
        <td className="px-4 py-2 w-3/4">{formatSubmissionDate(selectedApplication.submissionDateTime)}</td>
      </tr>
      <tr className="border-b bg-gray-200 border-white">
        <td className="px-4 py-2 font-semibold w-1/4 bg-gray-600 text-white">Website URL:</td>
        <td className="px-4 py-2 w-3/4">
          <a
            href={formatWebsiteUrl(selectedApplication.websiteUrl)}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline hover:text-blue-800"
          >
            {selectedApplication.websiteUrl}
          </a>
        </td>
      </tr>
      <tr className="border-b bg-gray-200 border-white">
        <td className="px-4 py-2 font-semibold w-1/4 bg-gray-600 text-white">Pitch Deck Link:</td>
        <td className="px-4 py-2 w-3/4">
          <a
            href={formatWebsiteUrl(selectedApplication.pitchDeckLink)}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline hover:text-blue-800"
          >
            {selectedApplication.pitchDeckLink}
          </a>
        </td>
      </tr>
      <tr className="border-b bg-gray-200 border-white">
        <td className="px-4 py-2 font-semibold w-1/4 bg-gray-600 text-white">Description:</td>
        <td className="px-4 py-2 w-3/4">{selectedApplication.description}</td>
      </tr>
      <tr className="border-b bg-gray-200 border-white">
        <td className="px-4 py-2 font-semibold w-1/4 bg-gray-600 text-white">Industry:</td>
        <td className="px-4 py-2 w-3/4">{selectedApplication.industry}</td>
      </tr>
      <tr className="border-b bg-gray-200 border-white">
        <td className="px-4 py-2 font-semibold w-1/4 bg-gray-600 text-white">Team Document Link:</td>
        <td className="px-4 py-2 w-3/4">
          <a
            href={formatWebsiteUrl(selectedApplication.teamDocumentLink)}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline hover:text-blue-800"
          >
            {selectedApplication.teamDocumentLink}
          </a>
        </td>
      </tr>
      <tr className="border-b bg-gray-200 border-white">
        <td className="px-4 py-2 font-semibold w-1/4 bg-gray-600 text-white">Product Description:</td>
        <td className="px-4 py-2 w-3/4">{selectedApplication.productDescription}</td>
      </tr>
      <tr className="border-b bg-gray-200 border-white">
        <td className="px-4 py-2 font-semibold w-1/4 bg-gray-600 text-white">Stage of Development:</td>
        <td className="px-4 py-2 w-3/4">{selectedApplication.stageOfDevelopment}</td>
      </tr>
      <tr className="border-b bg-gray-200 border-white">
        <td className="px-4 py-2 font-semibold w-1/4 bg-gray-600 text-white">Unique Selling Proposition:</td>
        <td className="px-4 py-2 w-3/4">{selectedApplication.uniqueSellingProposition}</td>
      </tr>
      <tr className="border-b bg-gray-200 border-white">
        <td className="px-4 py-2 font-semibold w-1/4 bg-gray-600 text-white">Target Audience:</td>
        <td className="px-4 py-2 w-3/4">{selectedApplication.targetAudience}</td>
      </tr>
      <tr className="border-b bg-gray-200 border-white">
        <td className="px-4 py-2 font-semibold w-1/4 bg-gray-600 text-white">Market Size:</td>
        <td className="px-4 py-2 w-3/4">{selectedApplication.marketSize}</td>
      </tr>
      <tr className="border-b bg-gray-200 border-white">
        <td className="px-4 py-2 font-semibold w-1/4 bg-gray-600 text-white">Competitors:</td>
        <td className="px-4 py-2 w-3/4">{selectedApplication.competitors}</td>
      </tr>
      <tr className="border-b bg-gray-200 border-white">
        <td className="px-4 py-2 font-semibold w-1/4 bg-gray-600 text-white">User Base:</td>
        <td className="px-4 py-2 w-3/4">{selectedApplication.userBase}</td>
      </tr>
      <tr className="border-b bg-gray-200 border-white">
        <td className="px-4 py-2 font-semibold w-1/4 bg-gray-600 text-white">Revenue:</td>
        <td className="px-4 py-2 w-3/4">{selectedApplication.revenue}</td>
      </tr>
      <tr className="border-b bg-gray-200 border-white">
        <td className="px-4 py-2 font-semibold w-1/4 bg-gray-600 text-white">Key Metrics:</td>
        <td className="px-4 py-2 w-3/4">{selectedApplication.keyMetrics}</td>
      </tr>
      <tr className="border-b bg-gray-200 border-white">
        <td className="px-4 py-2 font-semibold w-1/4 bg-gray-600 text-white">Email:</td>
        <td className="px-4 py-2 w-3/4">{selectedApplication.email}</td>
      </tr>
      <tr className="border-b bg-gray-200 border-white">
        <td className="px-4 py-2 font-semibold w-1/4 bg-gray-600 text-white">Phone:</td>
        <td className="px-4 py-2 w-3/4">{selectedApplication.phone}</td>
      </tr>
      <tr className="border-b bg-gray-200 border-white">
        <td className="px-4 py-2 font-semibold w-1/4 bg-gray-600 text-white">Unique Code:</td>
        <td className="px-4 py-2 w-3/4">{selectedApplication.uniqueCode}</td>
      </tr>
      <tr className="border-b bg-gray-200 border-white">
        <td className="px-4 py-2 font-semibold w-1/4 bg-gray-600 text-white">Comment:</td>
        <td className="px-4 py-2 w-3/4">{selectedApplication.comment || "No comments yet."}</td>
      </tr>
      <tr className="border-b bg-gray-200 border-white">
        <td className="px-4 py-2 font-semibold w-1/4 bg-gray-600 text-white">Status:</td>
        <td className="px-4 py-2 w-3/4">{selectedApplication.status || "Pending"}</td>
      </tr>
    </tbody>
  </table>
</div>
          {userDoc.data().Rights.isAdmin && (
  <div className="mt-6">
    {selectedApplication.comment ? (
      <>
      {isEditing ? (
        <>
          <textarea
            placeholder="Add a comment..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className="border border-gray-300 rounded-lg p-3 w-full mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <div className="flex justify-end">
            <button
              onClick={handleAccept}
              className="bg-green-600 text-white p-2 rounded-lg shadow-md hover:bg-blue-700 transition duration-300 ease-in-out mr-2"
            >
              Accept
            </button>
            <button
              onClick={handleReject}
              className="bg-red-600 text-white p-2 rounded-lg shadow-md hover:bg-red-700 transition duration-300 ease-in-out"
            >
              Reject
            </button>
          </div>
        </>
      ) : (
        <button
          onClick={() => setIsEditing(true)}
          className="bg-gray-600 flex justify-center items-center text-white p-2 rounded-lg shadow-md hover:text-black hover:bg-gray-300 transition duration-300 ease-in-out"
        >
          Edit Comment<GoPencil  className="ml-2"/>
        </button>
      )}
    </>
    ) : (
      <>
      <textarea
        placeholder="Add a comment..."
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        className="border border-gray-300 rounded-lg p-3 w-full mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <div className="flex justify-end">
        <button
          onClick={handleAccept}
          className="bg-green-600 text-white p-2 rounded-lg shadow-md hover:bg-blue-700 transition duration-300 ease-in-out mr-2"
        >
          Accept
        </button>
        <button
          onClick={handleReject}
          className="bg-red-600 text-white p-2 rounded-lg shadow-md hover:bg-red-700 transition duration-300 ease-in-out"
        >
          Reject
        </button>
      </div>
    </>
    )}
  </div>
)}
          </div>
            </>
          )}
        </Modal>
    </div>

    </div>
    
    </>
    
  );
};

export default AdminDashboard;
