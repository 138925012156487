// firebase.js

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";  

const firebaseConfig = {
  apiKey: "AIzaSyBx3PfFmB6hGmsPhQEbPYn9qGL_m0A19yI",
  authDomain: "klic-portal.firebaseapp.com",
  projectId: "klic-portal",
  storageBucket: "klic-portal.appspot.com",
  messagingSenderId: "964557445184",
  appId: "1:964557445184:web:4de0327584b843b7c327a5",
  measurementId: "G-ZGNF0J2L18"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);  

export { db, auth };
